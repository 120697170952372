import { graphql } from "gatsby";
import { first, get, isNil, sortBy } from "lodash";
import React, { useContext } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import TilesCardGrid, { TileCardItem } from "../../components/cardgrid/tile-cardgrid";
import Hero from "../../components/hero";
import Intro from "../../components/intro/wildcard";
import {
  TideItemForCountry,
  TideItemForHeroComponent,
  TideItemForInfoBlocksComponent,
  TideItemForIntroComponent,
  TideItemForNavigationSection,
  TideItemForOverviewComponent,
  TideItemForPageTitleComponent,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWildcardCountryPage,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../component";
import MapBlocksComponents from "./map-blocks-components";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { FilterProvider } from "../../contexts/filter-provider";
import FacetedSearch from "../../components/facetedsearch";
import PageTitle from "../../components/page-title";

interface MapCountryPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForCountry;
  usps: TideItemForUspFolder;
  breadcrumbs: Breadcrumb[];
  wildcardCountryPage: TideItemForWildcardCountryPage;
}

const MapCountryPageComponents: React.FC<MapCountryPageComponentsProps> = ({ website, page, usps, breadcrumbs, wildcardCountryPage }) => {
  const { tournaments, teams } = useContext<ComponentContextType>(ComponentContext);

  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      const quickLinksSection = first(getChildItemsWithTypename("TideItemForNavigationSection", page) as TideItemForNavigationSection[]);
      return (
        <Hero
          key={componentItem.id}
          image={parse(page.content?.general?.hero)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          extraClass="header--list-page"
          quickLinksSection={quickLinksSection}
        />
      );
    },
    TideItemForPageTitleComponent: (componentItem: TideItemForPageTitleComponent) => {
      return <PageTitle key={componentItem.id} title={parse(page.content?.general?.title)} image={parse(page.content?.general?.flag)} />;
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      return (
        <Intro
          key={componentItem.id}
          text={parse(page.content?.general?.intro)}
          // title={parse(page.content?.general?.title)}
          website={parse(website)}
          usps={parse(usps)}
        />
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      const type = first(componentItem.content?.general?.type);
      switch (type) {
        case "Competitions":
          const tournamentCardItems = tournaments
            .filter((n) => n.content?.general?.countryId === page.content?.general?.countryId && n.content?.general?.popular)
            .sort((a, b) => {
              const orderA = a.content?.general?.sortingorder;
              const orderB = b.content?.general?.sortingorder;

              if (orderA === undefined || orderA === null) return 1;
              if (orderB === undefined || orderB === null) return -1;
              return orderA - orderB;
            })
            .map(
              (tournament) =>
              ({
                title: tournament.name,
                image: tournament.content?.general?.logo,
                path: tournament.content?.general?.path,
              } as TileCardItem)
            );
          return <TilesCardGrid key={componentItem.id} tiles={tournamentCardItems} />;
        case "Clubs":
          const clubCardItems = teams
            .filter((n) => n.content?.general?.countryId === page.content?.general?.countryId && n.content?.general?.popular)
            .sort((a, b) => {
              const orderA = a.content?.general?.sortingorder;
              const orderB = b.content?.general?.sortingorder;

              if (orderA === undefined || orderA === null) return 1;
              if (orderB === undefined || orderB === null) return -1;
              return orderA - orderB;
            })
            .map(
              (club) =>
              ({
                title: club.name,
                image: club.content?.general?.logo,
                path: "clubs/" + club.content?.general?.path,
              } as TileCardItem)
            );
          return <TilesCardGrid key={componentItem.id} tiles={clubCardItems} />;
        case "Packages":
          return (
            <FilterProvider key={componentItem.id}>
              <FacetedSearch page="countries" itemsPerPage={12}></FacetedSearch>
            </FilterProvider>
          );
        default:
          break;
      }
    },
    TideItemForInfoBlocksComponent: (componentItem: TideItemForInfoBlocksComponent) => {
      return <MapBlocksComponents key={componentItem.id} blocks={parse(page.childItems)} games={[]} />;
    },
  };

  return (
    <>
      {wildcardCountryPage?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapCountryPageComponents;

export const query = graphql`
  fragment TideItemForOverviewComponentFragment on TideItemForOverviewComponent {
    __typename
    id
    content {
      general {
        title
        type
      }
    }
  }
`;
